import React from 'react';
 

function Logo() {
return(
<div id="slogan">
         <a href="/home">
        <h2 className="sehr-dunkel-grau">thies wachter</h2>
        <h2 className="dunkel-grau sperren"> photographer</h2>
        <h2 className="hell-grau word-space-2">zurich - berlin</h2>
         </a>
      </div>
)}
export default Logo; 