
import React from 'react'
import Logo from "../Logo";
import Nav from "../Nav";
import Gallery from '../Gallery'; 

function Exhibitions() {
  let images =[];
  if (window.innerWidth > 768){
  images = [
  {
      url: "images/exhibitions/exhibitions-1-mobile.png",
      alt: ""
  },
   { 
      url: "images/exhibitions/exhibitions-2.png",
      alt: ""
    }
   ];
  }else{
   images = [
      {
          url: "images/exhibitions/exhibitions-1-mobile.png",
          alt: ""
      },
       { 
          url: "images/exhibitions/exhibitions-2.png",
          alt: ""
        }
       ];
  }


  return (
    <div className='exhibitions'>
       <div id="container">
        <div id="left">
          <Logo />

          <Nav />
        </div>
        <div id="right">
          <div id="text">
            <h2>Exhibitions</h2>
          <p>2020 <br />
  Akademie der Künste Berlin, group exhibition</p>
<p>2019 <br />
  Tsekh Gallery  Kiev. Grand single exhibition on invitation of the Embassy of Switzerland in  Ukraine, Kiev: „High Rises and High Places“ / Examples of contemporary Swiss Architecture</p>
<p>2014 - 2015 <br />
  Kunsthalle  Zurich: Exhibitions Thomas Müllenbach / Avery Singer, exhibition poster</p>
<p>2012 - 2015<br />
  Foreign Policy Centre, Bern: „Swiss Positions. 33 takes on sustainable  approaches to building“ Confederation Suisse, Federal Department of Foreign  Affairs (FDFA), Center of Foreign Cultural Politics, travelling group  exhibition, world-wide</p>
<p>2012 - 2013<br />
  SAM, Swiss  Architecture Museum, Basel: „BILDBAU“, group exhibition</p>
<p>2012 - 2013<br />
  Deutsches  Architekturmuseum, Frankfurt am Main „International Highrise Award 2012“, group  exhibition</p>
<p>2011 - 2012<br />
  Museum für  Gestaltung, Zurich: „Highrise - Idea and Reality“, group exhibition</p>
<p>2011 - 2012<br />
Gigon / Guyer  Architects, Zurich: „Works &amp; Projekts 2001 - 2011“, exhibition poster, ETH  Zurich</p>

          </div>
        </div>
      </div>
      <Gallery img={images} classes="special"/>
      <div className='people-image'>
      <img src="images/exhibitions/exhibitions-2.png" alt=""  /></div>
    </div>
  )
}

export default Exhibitions
