import React from 'react'
import Logo from "../Logo";
import Nav from "../Nav";
import Gallery from "../Gallery";

function Infrastructure() {
  const images = [
    {
      url : "images/infrastructure/1_t-wa_thies_wachter_bauhaus.jpg",
      alt:""
    },
  { 
      url : "images/infrastructure/2_t-wa_thies_wachter_grin.jpg",
      alt :""
  },
  {
      url: "images/infrastructure/3_t-wa_thies_wachter_beijing.jpg",
      alt: ""
    },
   { 
      url: "images/infrastructure/4_t-wa_thies_wachter_shanghai.jpg",
      alt: ""
    },
    { 
       url: "images/infrastructure/5_t-wa_thies_wachter_enl.jpg",
       alt: ""
     },
     { 
        url: "images/infrastructure/6_t-wa_thies_wachter_moka.jpg",
        alt: ""
      }
];
  return (
    <div className='infrastructure'>
      <div id="container">
        <div id="left">
          <Logo />

          <Nav />
        </div>
        <div id="right">
          <div id="text">
          <h2>Infrastructures<br/>Die Grafik derStädte</h2> 
          continuous sequence of urban sections and details<br />
          of New York, Los Angeles, San Francisco, Beijing,<br />
          Shanghai, Berlin, Habana, Kiev, Zurich and Kiel 
          </div>
        </div>
      </div>
      <Gallery img={images} classes="six infrastructure"/>
    </div>
  );
}

export default Infrastructure
