import React from "react";
import Logo from "../Logo";
import Nav from "../Nav";
import Gallery from "../Gallery";

function Thieswachter() {
  const images = [
    {
      url: "images/thieswachter/1_t-wa_thies_wachter_fifa.jpg",
      alt: "",
    },
    {
      url: "images/thieswachter/2_t-wa_thies_wachter_wuerth_house.jpg",
      alt: "",
    },
    {
      url: "images/thieswachter/3_t-wa_thies_wachter_Volodymyrsski_descent.jpg",
      alt: "",
    },
    {
      url: "images/thieswachter/4_t-wa_thies_wachter_loewenbrau.jpg",
      alt: "",
    },
    {
      url: "images/thieswachter/5_t-wa_thies_wachter_migros_city.jpg",
      alt: "",
    },
    {
      url: "images/thieswachter/6_t-wa_thies_wachter_prime_tower.jpg",
      alt: "",
    },
  ];
  return (
    <div className="wachter">
      <div id="container">
        <div id="left">
          <Logo />

          <Nav />
        </div>
        <div id="right">
          <div id="text">
            <h2>Thies Wachter</h2>
            <p>
              is a Zurich- and Berlin based photographer <br/>
              specializing in architecture, spaces and infrastructures <br/>
              <br/>
              Foundation of the architectural photography practice<br/>
              in Hamburg 2005
            </p>
            <p>
              Clients include Gigon / Guyer Architects Zurich, <br/>
              Würth International AG, Credit Suisse, the FIFA<br/>
              and Young & Rubicam Switzerland
            </p>
            <p>International exhibitions | Worldwide publications</p>
          </div>
        </div>
      </div>
      <Gallery img={images} classes="six wachter" />
    </div>
  );
}

export default Thieswachter;
