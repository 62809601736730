import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import Home from "./components/Pages/Home";
import Clients from "./components/Pages/Clients";
import Exhibitions from "./components/Pages/Exhibitions";
import Publications from "./components/Pages/Publications";
import Infrastructure from "./components/Pages/Infrastructure"
import Thieswachter from "./components/Pages/Thieswachter";
import Contact from "./components/Pages/Contact";
import Imprint from "./components/Pages/Imprint";


import "./App.css";

function App() {
 
  return (
    <>
      <Router>
        <div id="home-container">
          <Link to="/home">
            <p id="modal"></p>
          </Link>
        </div>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/publications" element={<Publications />} />
          <Route path="/exhibitions" element={<Exhibitions />} />
          <Route path="/infrastructure" element={<Infrastructure />} />
          <Route path="/thieswachter" element={<Thieswachter />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/imprint" element={<Imprint />} />
        </Routes>
      </Router>
    </>
  );
}
export default App;
