import React, {useState, useRef} from 'react'
import {FaChevronRight,FaChevronLeft } from "react-icons/fa";

function Gallery(props) {
  const data = props.img
  
  const imageref = useRef()
  const imgcontainref = useRef()

  const [scroll, setScroll] = useState(0)

  const moveRight = () =>{
    let step =  imageref.current.width
    if (scroll < window.innerWidth)  setScroll(scroll + step)
    if (scroll === 0) imgcontainref.current.scrollLeft += step
    else imgcontainref.current.scrollLeft += scroll
    // console.log(" R " + scroll + "  " + step + " " + window.innerWidth);
  }
  const moveLeft = () =>{  
    let step =  imageref.current.width
    if (scroll > 0) {
      setScroll(scroll - step )
      imgcontainref.current.scrollLeft  -= scroll
    }else  setScroll(0)
    // console.log(" L " + scroll+"  " + step + " " + window.innerWidth);
  }
  return (
    <>
      <div id="bottom">
        <div id="left-arrow" onClick={moveLeft}><FaChevronLeft/></div>
        <div id="right-arrow" onClick={moveRight}><FaChevronRight/></div>
        <div id="images" className={props.classes} ref={imgcontainref}>
          {  
            data.map((elem,i)=>
            <img key={i} src={elem.url} alt={elem.alt} ref={imageref}/>
            )
          }
        </div>
        

      </div>
    </>
  )
}

export default Gallery
