import React from 'react'
import Logo from "../Logo";
import Nav from "../Nav";
import Gallery from '../Gallery';
function Clients() {

  const images = [
    {
      url : "images/clients/1_t-wa_thies_ wachter_mtl50.jpg",
      alt:""
    },
  { 
      url : "images/clients/2_t-wa_thies_wachter_loewenbraeu_migros museum.jpg",
      alt :""
  },
  {
      url: "images/clients/3_t-wa_thies_wachter_westlink_zurich.jpg",
      alt: ""
    },
   { 
      url: "images/clients/4_t-wa_thies_wachter_platform_zurich.jpg",
      alt: ""
    },
    { 
      url: "images/clients/5_t-wa_thies_wachter_platform_zurich.jpg",
      alt: ""
    }
];
  return (
    <div className='clients'>
       <div id="container">
        <div id="left">
          <Logo />

          <Nav active="active"/>
        </div>
        <div id="right">
          <div id="text">
          <h2>Selected Clients</h2>
            Gigon / Guyer Architects, Zurich<br/>
            Young & Rubicam Group Switzerland AG / Futurecom, Zurich<br/>
            FIFA, Fédération Internationale de Football Association, Zurich<br/>
            David Chipperfield Architects and Mokarchitecture AG, Berlin – Zurich<br/>
            Credit Suisse AG, Zurich<br/>
            Sauerbruch Hutton, Competition CZZ, Berlin<br/>
            Würth International AG, Chur<br/>
            atelier ww Architects SIA, Zurich<br/>
            Zurich Investment Foundation<br/>
            Kongresshaus Foundation Zurich<br/>
            Löwenbräukunst, Zurich<br/>
            Bauatelier Hamburg<br/>
            SAM Architects, Zurich<br/>
            s a i s Architects, Berlin<br/>
            ISS Facility Services AG, Zurich<br/>
            Evoq Communications AG, Zurich<br/>
            A2017+ Architects, Zurich<br/>
            ppa Architects, Hamburg
          
          </div>
        </div>
      </div>
      <Gallery img={images} classes="five client"/>
    </div>
    
  )
}

export default Clients
