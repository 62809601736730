import React from "react";
import Logo from "../Logo";
import Navhome from "../Navhome";
import Gallery from "../Gallery";

const Home = () => {
   const images = [
    {
      url : "images/1_t-wa_thies_wachter_photographer.jpg",
      alt:""
    },
  { 
      url : "images/2_t-wa_thies_wachter_locarno.jpg",
      alt :""
  },
  {
      url: "images/3_t-wa_thies_wachter_ravensberg_ir.jpg",
      alt: ""
    },
   { 
      url: "images/4_t-wa_thies_wachter_sam_tgiesa_crapera_kiev.jpg",
      alt: ""
    }
   ];

 
  return (
    <div className="home">
      <div id="container">
        <div id="left">
          <Logo />

          <Navhome />
        </div>
        <div id="right">
          <div id="text"></div>
        </div>
      </div>
      <Gallery img={images}/>
    </div>
  );
};

export default Home;
