import React from 'react'
import Logo from "../Logo";
import Nav from "../Nav";
import Gallery from "../Gallery"

function Publications() {
  const images = [
    {
      url : "images/publ/1_t-wa_thies_wachter_detail.jpg",
      alt:""
    },
  { 
      url : "images/publ/2_t-wa_thies_wachter_ey_Platform_zurich.jpg",
      alt :""
  },
  {
      url: "images/publ/3_t-wa_thies_wachter_domus.jpg",
      alt: ""
    },
   { 
      url: "images/publ/4_t-wa_thies_wachter_mobimo tower_zurich.jpg",
      alt: ""
    },
    { 
      url: "images/publ/5-t-wa_thies_wachter_prime tower_zurich.jpg",
      alt: ""
    },
    { 
      url: "images/publ/6_t-wa_thies_wachter_moka.jpg",
      alt: ""
    },
    { 
      url: "images/publ/7_t-wa_thies_wachter_hochparterre.jpg",
      alt: ""
    }
 ];

  return (
    <div className="publications">
      <div id="container">
        <div id="left">
          <Logo />

          <Nav />
        </div>
        <div id="right">
          <div id="text">
            <h2>Selected Publications</h2>
           Swiss International Airlines<br/>
            UBS, Basel<br/>
            City of Zurich<br/>
            Skyscrapercenter Chicago, CTBUH<br/>
            museums.ch<br/>
            Archithese<br/>
            Deutsches<br/> Architekturmuseum, Frankfurt am Main<br/>
            Artron, Beijing Shenzhen Shanghai<br/>
            Akademie der Künste Berlin<br/>
            Deutsche Bank Suisse, Zurich<br/>
            a+u, Tokyo<br/>
            ABACT, São Paulo<br/>
            Kunsthalle Zurich<br/>
            Embassy of Switzerland in Ukraine, Kiev<br/>
            Artforum, New York<br/>
            SRF, Zurich<br/>
            Senator Lounge Zurich Airport<br/>
            Museum für Gestaltung, Zurich<br/>
            FIFA World Football Museum, Zurich<br/>
            Svizzera 240 Biennale di Venezia 2018<br/>
            ORF, Wien<br/>
            Parkinn by Radisson, U.S.<br/>
            Universität Zurich<br/>
            db deutsche bauzeitung<br/>
            Swiss Financial Market Supervisory Authority FINMA, Bern<br/>
            Migros Museum, Zurich<br/>
            Switzerland Federal Department of Foreign Affairs, Bern<br/>
            Ernst & Young Ltd. Schweiz, Zurich<br/>
            Schüco International KG, Bielefeld<br/>
            VectorWorks / Computerworks, Basel<br/>
            Geberit International AG, Jona<br/>
            Hochparterre AG, Zurich<br/>
            Wettbewerbe Aktuell<br/>
            SAM, Schweizerisches Architekturmuseum, Basel
          </div>
        </div>
      </div>
      <Gallery img={images} classes="seven publications"/>
    </div>
  )
}

export default Publications
