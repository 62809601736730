import React, {useState} from "react";
import { NavLink } from "react-router-dom";
import {FaBars, FaTimes} from "react-icons/fa";


function Nav(){
  const [click, setClick] = useState(false);
  const handleClick = () =>{
     setClick(!click);
     let x = document.getElementById("nav-menu"); 
     let icon = document.querySelector('.burger');
     if (x.style.display === "block") {
       x.style.display = "none";
       icon.classList.remove('open');
     } else {
       icon.classList.add('open');
       x.style.display = "block";
     }
    }
 
  return(
    <>
   <div id="menu" className="burger">

    <div className="menu-icon" onClick={handleClick}>
      {click ? <FaTimes /> : <FaBars />}
    </div> 
    <ul id ="nav-menu" className={click ? 'nav-menu active' : 'nav-menu'}>
    <li className="nav-item">
      <NavLink to="/clients" className="nav-links">
        Selected Clients
      </NavLink>
    </li>
    <li className="nav-item">
      <NavLink to="/publications" className="nav-links">
        Selected Publications
      </NavLink>
    </li>
    <li className="nav-item">
      <NavLink to="/exhibitions" className="nav-links">
         Exhibitions
      </NavLink>
    </li>
    <li className="nav-item">
      <NavLink to="/infrastructure" className="nav-links">
          Infrastructures | Die Grafik der Städte
      </NavLink>
    </li>
    <li className="nav-item">
      <NavLink to="/thieswachter" className="nav-links">
        Thies Wachter
      </NavLink>
    </li>
    <li className="nav-item">
      <NavLink to="/contact" className="nav-links">
        Contact
      </NavLink>
    </li>
    <li className="nav-item">
      <a href="https://world-architects.com/en/thies-wachter-zurich/about" className="nav-links">
        World Architects
      </a>
    </li>
    <li className="nav-item">
        <NavLink to="/imprint" className="nav-links">
        Imprint | Privacy Notice
      </NavLink>
    </li>
    </ul>
   </div> 
    </>


  );
}
export default Nav;