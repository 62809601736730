import React from 'react'
import Logo from "../Logo";
import Nav from "../Nav";
import Gallery from "../Gallery";

function Contact() {
  const images = [
    {
      url : "images/contact/1_t-wa_thies_wachter_prime tower.jpg",
      alt:""
    },
  { 
      url : "images/contact/2_t-wa_thies_wachter_wuerth_house.jpg",
      alt :""
  },
  {
      url: "images/contact/3_t-wa_thies_wachter_Clouds.jpg",
      alt: ""
    },
   { 
      url: "images/contact/4_t-wa_thies_wachter_West.jpg",
      alt: ""
    }
];
  return (
    <div className="contact">
      <div id="container">
        <div id="left">
          <Logo />

          <Nav />
        </div>
        <div id="right">
          <div id="text">
          <h2>Contact</h2>
          <p>
          zürichbergstr. 46a<br />
          ch-8044 zurich<br />
          +41 77 44 88 311</p>
          <p>
           lindenstr. 75a<br />
          d-10969 berlin <br />
          +49 176 239 240  38</p>
          <p>
          <a href="mailto:mail@t-wa.com">mail@t-wa.com</a><br />
          <a href="https://www.t-wa.com">www.t-wa.com</a></p>
          <p className='fett'>For general inquiries: <br />
          <a href="mailto:mail@t-wa.com">mail@t-wa.com</a></p>
          </div>
        </div>
      </div>
      <Gallery img={images} classes="four contact"/>
    </div>
  );
}

export default Contact
